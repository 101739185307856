$(document).on('change', '.references-filter select', function () {
    document.forms.referencesFilter.submit();
});

if ($('figure.image').length && $('.hidden-gallery').length) {
    $('figure.image').each(function() {
        var gallery_id = $('.hidden-gallery a:first').data('lightbox');

        $(this).addClass('open-gallery');
        $(this).find('img').wrap('<a href="' + $(this).find('img').attr('src') + '" data-lightbox="' + gallery_id + '"></a>');
    });
}

$(document).on('click', '.site-header .menu-toggler', function (e) {
    e.preventDefault();
    $(this).addClass('open');
    $('.site-header .nav-menu').fadeIn(200).addClass('open').append('<span class="close"><i class="fa fa-times"></i></span>');
});

$(document).on('click', '.site-header .nav-menu .close', function (e) {
    e.preventDefault();
    $('.site-header .menu-toggler').removeClass('open');
    $('.site-header .nav-menu').removeClass('open').hide();
    $(this).remove();
});


if ($('.reference-slideshow').length) {
    var refslick = $('.reference-slideshow').slick({
        slide: '.slick-el',
        adaptiveHeight: false,
        autoplay: false,
        arrows: true,
        swipe: true,
        variableWidth: false
    });
}
